.hero{
    display: flex;
    justify-content: space-between;
}

.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 2;
    display: flex;
    gap: 3rem;
    flex-direction: column;
    
}

.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}

.the-best-ad{
    margin-top: 6rem;
    background-color:grey;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content:flex-start;

}

.the-best-ad>span{
    z-index: 2;
}

.the-best-ad>div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;


}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;

}

.hero-text>div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;


}

.figures{
    display: flex;
    gap: 2rem;
}

.figures>div{
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
}

.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.hero-buttons>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 8rem;
}

.hero-buttons>:nth-child(2) {
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}

.right-h>.btn{
    position: absolute;
    right: 2rem;
    top: 3rem;
    color: black;
}

.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: black;
    width: fit-content;
    padding: 1rem;
    align-items: center;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}

.heart-rate>img{
    width: 12rem;
    height: 10rem;
    padding: 10px;

}

.heart-rate>span:nth-child(2){
    color: var(--gray);

}
.heart-rate>span:nth-child(3) {
    color: white;
    font-style: 1.5rem;

}

.hero-image{
    position: absolute;
    right: 20rem;
    top: 10rem;
    width: 18rem;
}
.hero-image-back{
    position:absolute;
    top: 4rem;
    right: 45rem;
    z-index: -1;
    width: 15rem;
}

.calories{
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 38rem;
    right: 62rem;

}

.calories>img{
    width: 4rem;
    

}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
}

.calories> div> :nth-child(1){
    color: black;
    font-weight: bold;

}

.calories> div> :nth-child(2) {
    color: white;
    font-size: 1.2rem;

}

.hero-blur{
    width: 22rem;
    height: 30rem;
    left: 0;
}

@media  screen and (max-width:768px){
    .hero{
        flex-direction: column;
    }

    .hero-blur{
        width: 14rem;

    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }

    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }

    .hero-text>div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }

    .hero-buttons{
        justify-content: center;
    }
    .figures>div>span:nth-of-type(1){
        font-size: large;
    }
    .figures>div>span:nth-of-type(2) {
        font-size: small;
    }
    .right-h>.btn{
        bottom: 7rem;
        width: 4rem;
        height: 2rem;
    }

.right-h{
    position: relative;
    background: none;
}
.heart-rate{
    left: 1rem;
    top: 2rem;
}
.heart-rate>img{
    width: 1rem;
    height: 1rem;
}
.calories{
    position: relative;
    top: 5rem;
    left: 2rem;
}

.calories>img{
    width: 2rem;
}

.calories>div>span:nth-child(2){
    color: white;
    font-size: 1rem;
}

.hero-image{
    position: relative;
    width: 15rem;
    left: 7rem;
    top: 4rem;
    align-self: center;
}

.hero-image-back{
    width: 15rem;
    left: 2rem;  
    top: 0rem;
}

}
    
